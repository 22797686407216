$(document).ready(function() {
   	//	-------------------------------------------------
	// FORM Utilities

	$('body').on('click','.search-btn',function(){$('.search').toggleClass('searching');$('.FormSeach').toggleClass('is-searching');setTimeout(function(){$('#search_box_header').focus();},300);});

	// when upload a file, get it's name and display it in the label
	$('input[type="file"]').change(function (e) {
	    var fileName = e.target.files[0].name;

	    $(this).parent().find('label').text(fileName);
	});

	// when submiting a form, disable the button and show "sending..." in in
	$('form').submit(function (e) {
	    var submit_button = $(this).find('button[type="submit"]');
	    var span_loading_msg = submit_button.parent().find('span');

	    submit_button.addClass('disabled').text(span_loading_msg.text());
	});

	//  -------------------------------------------------
	//  TILES Utility

		$(".match-height").each(function(){
			// Get highest parent from element (of type .row)
			var highestParent = $(this).parents(".row").last(); 
			// Get height of reference parent
			var refHeight = highestParent.height();
			// Give determined height to element
			$(this).height(refHeight);
		});


	//  -------------------------------------------------
	//  ACCORDIONs - Slide down - up

		var accordion = $(".Accordions");
		var accordion_length = accordion.length;

		if (accordion_length > 0) {

			var block = $(".Accordions_block");
			var header = $(".Accordions_header");
			var title = $(".Accordions_title");
			var content = $(".Accordions_content");
			
			header.click(function(){

				var self = $(this);
				
				if (!accordion.hasClass('Accordions-collapse')) {
					var accordions_active = $('.Accordions_block .Accordions_header.active');
					
					accordions_active.removeClass('active');
					
					if (accordions_active.length > 0) {
						accordions_active.next().slideUp(function(){
							if (self[0] != $(this).prev()[0]) {
								slideAccordion('toggle', self);
							}
						});						
					}
					else {
						slideAccordion('toggle', self);
					}
				}
				else {
					slideAccordion('toggle', self);									
				}
			});

		};

		function slideAccordion(typeSlide,accordion) {
			switch(typeSlide.toLowerCase()) {
			    case 'up':
			        accordion.removeClass("active");
					accordion.next().slideUp(200);
			        break;
			    case 'down':
			        accordion.addClass("active");
					accordion.next().slideDown(200);
			        break;
			    case 'toggle':
			 		accordion.toggleClass("active");
					accordion.next().slideToggle(200);
			        break;
			}
		}

	//  -------------------------------------------------
	//  TABS Utility


	$('.nav-tabs a').click(function (e) {
	  e.preventDefault();
	  $(this).tab('show');
	});


	$(window).on('load', function(){
	/* ////////////////////////////////////////
	//
	// Init
	//
	/////////////////////////////////////// */

	//du code soit pour le loading mask ou pour initialiser le loading du site

		}).scroll(function(){
			scrollContent();
		});

		/* ////////////////////////////////////////
			//
			// Scroll content
			//
			/////////////////////////////////////// */
		function scrollContent(){
			var totalScroll = $(document).height() - $(window).height();

			//if(browserMobile){
			newScroll = $(window).scrollTop();
			// } else {
			// 	if(whichBrs() == 'Safari' || whichBrs() == 'Chrome'){
			// 		newScroll = $('body').scrollTop();
			// 	} else {
			// 		newScroll = $('html,body').scrollTop();
			// 	}
			// }

			currentScroll = newScroll;
			/* To-load
			================================================== */

			/* new system */
			$('.ToAnim').each(function(){
				var object = $(this);					
				if(newScroll + $(window).height() * 1 > $(this).offset().top){
					object.addClass('IsAnim');
					object.removeClass('DoneAnim');
				}
				else if(newScroll + $(window).height() < $(this).offset().top) {
					object.removeClass('IsAnim');
					object.addClass('DoneAnim');
				}
			});			
		}

		setTimeout(function(){
			scrollContent();
		}, 1000);

		

			// $('#background > div > img').each(function(){
			// 	var posLeft = ($(this).width() - $(this).parent().width()) / 2;
			// 	$(this).css({'left': - posLeft});
			// });

			scrollContent();


	//  -------------------------------------------------
	//  TOGGLE Utility that works

	// get the data-toggler and its value
	$('[data-toggler]').click(function() {
		var self = $(this);
		var mainValue = $(this).attr('data-toggler');

		// then execute the function to toggleClass to all its matching toggler
		TheToggler(mainValue, self);
	});

	function TheToggler(mainValue, self) {
		var on = 'is-active';

		// get all other toggler with the same value
		$('[data-toggler]').each(function(){
			if ($(this).attr('data-toggler') == mainValue) {
				$(this).toggleClass(on);
			}
		});

		// get all element that needs to be toggle
		$('[data-toggle]').each(function(){

			// check if matching value
			var value = $(this).attr('data-toggle');
			if (mainValue == value) {
				$(this).toggleClass(on);
			}
		});
	}
});