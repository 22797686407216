var emploisuccess = function() {
    $('.js-jobForm').fadeOut(500, function() {
        $('.emploisuccess').fadeIn();
    });
};
var emploierror = function() {
    $('.js-jobForm .btn').fadeIn();
    $('#js-error-emploi').html('<p class="text-error">Une erreur est survenue, veuillez r&eacute;essayer plus tard.</p>');
    $('html, body').animate({
        scrollTop: $('#posez').offset().top - 75
    }, 300);
};
$(document).ready(function() {
    $('.pignose-calendar-button-schedule-container').parent().addClass("schedule-active");
    $("a[href='']").on('click', function() {
        return false;
    });
    $('.single-item').slick({
        dots: false,
        arrows: true,
        infinite: true,
        speed: 300,
        slidesToShow: 1
    });
    $("body").on('change', '#UploadCV', function() {
        $(this).parent().find('label').html($(this).val().split('\\').pop().split('/').pop());
    })
    $(".scrollto").click(function() {
        var scrolltarget = $(this).data('target');
        $('html, body').animate({
            scrollTop: $(scrolltarget).offset().top
        }, $(this).data('duration'));
        return false;
    });
    $(".postuler").click(function() {
        var lenom = $(this).parent().siblings('.Accordions_header').first().find('h2').html();
        $("#js-emploiliste option").each(function() {
            $(this).removeAttr('selected');
            if ($(this).html() == lenom) {
                $(this).attr('selected', 'selected');
            }
        })
    });
    $('.js-jobForm .btn').click(function() {
        $('.js-jobForm').attr('action', '/json?module=emploiform&method=submit');
    });
    $('.js-jobForm').submit(function() {
        $(this).find('.btn').fadeOut();
    });
    $(".dropdown").hover(function() {
        $('.dropdown-menu', this).not('.in .dropdown-menu').stop(true, true).slideDown("400");
        $(this).toggleClass('open');
    }, function() {
        $('.dropdown-menu', this).not('.in .dropdown-menu').stop(true, true).slideUp("400");
        $(this).toggleClass('open');
    });
    equalheight = function(a) {
        var e, b = 0,
            c = 0,
            d = new Array;
        $(a).each(function() {
            if (e = $(this), $(e).height("auto"), topPostion = e.position().top, c != topPostion) {
                for (currentDiv = 0; currentDiv < d.length; currentDiv++) d[currentDiv].height(b);
                d.length = 0, c = topPostion, b = e.height(), d.push(e)
            } else d.push(e), b = b < e.height() ? e.height() : b;
            for (currentDiv = 0; currentDiv < d.length; currentDiv++) d[currentDiv].height(b)
        })
    }, $(window).on("load resize ready", function() {
        equalheight(".subsribe .subsribe-box")
    });
    rightsecmargin = function() {
        var imgHeight = $('.title-sec-right img').height();
        var bannerHeight = $('.title-sec').height();
        var bothHeight = (imgHeight - bannerHeight) + 10;
        $('.program-detail-box').css('margin-top', (bothHeight));
    }, $(window).on("load resize ready", function() {
        if ($(window).width() >= 1200) {
            rightsecmargin(".program-detail-box")
        }
    });
    $('.menu-toggle').click(function() {
        $(this).toggleClass('toggle-active');
        $('.MobileNav').toggleClass('is-active');
    });
    var mobNav = $('.MobileNav ul');
    var mobNavSub;
    $('.ic-arrow-down').click(function(e) {
        var arrow = $(this);
        var mobNavSub = $(this).parents('.has-child');
        mobNavSub.toggleClass('is-open');
        NavToggler(mobNavSub);
    });

    function NavToggler(mobNavSub) {
        mobNavSubUl = mobNavSub.find('ul');
        mobNavSubUl.slideToggle();
    };
    $(".panel-heading").click(function() {
        if ($(window).width() <= 767) {
            $(".panel-body").slideUp();
            $(this).parent().find(".panel-body").slideDown();
        }
    })
});
var last_known_scroll_position = 0;
var ticking = false;
var stickyNav = $('.StickyNav');

function showStickyNav(scroll_pos) {
    if (scroll_pos > 125) {
        stickyNav.addClass('is-visible');
    } else {
        stickyNav.removeClass('is-visible');
    }
}
window.addEventListener('scroll', function(e) {
    last_known_scroll_position = window.scrollY;
    if (!ticking) {
        window.requestAnimationFrame(function() {
            showStickyNav(last_known_scroll_position);
            ticking = false;
        });
    }
    ticking = true;
});