if ($.browser.msie) {
	$('html').addClass('ie').addClass('ie'+parseInt($.browser.version,10));
}


var emploisuccess = function() {
	$('.js-emploiForm').fadeOut(500,function() {$('.emploisuccess').fadeIn();});
};

var emploierror = function() {
	$('.js-emploiForm .btn').fadeIn();
	$('#js-error-emploi').html('<p class="text-error">Une erreur est survenu, veuillez r&eacute;essayer plus tard.</p>');
	$('html, body').animate({
		scrollTop:$('#js-error-emploi').offset().top
	}, 300);
};



var retina = window.devicePixelRatio > 1;
$(function() {

	$(document).on('click', '.search-btn', function(e)
	{
		setTimeout(function(){$('#search_box_header').focus();}, 500);
		
	});

	var device = Detect();
    if (device=='smartphone') {
        $('body').addClass('mobile');
    }

    if (device=='desktop') {
        $('body').addClass('computer');
    }
	if ($('.js-contactForm').length>0) {
		$('.js-contactForm').validate({
			errorPlacement: function(error,element) {
				element.closest('label').addClass('error-label');
				error.insertAfter(element);
				},
			success: function(label,element) {
				$(element).closest('label').removeClass("error-label");
				}
			});
		}
	$('.js-contactForm').submit(function() {
		var obj = $(this);
		if (obj.valid()) {
			$.post('/json?module=contactform&method=submit',$(this).serializeArray(),function(e) {
				if(e.status!="Erreur")
					obj.hide().next().removeClass('hide').show();
				else{
					$('.js-error-form').remove();
					$('.js-contactForm').append('<div class="js-error-form alert alert-danger" role="alert">'+e.message+'</div>');
				}
			});
		}
		return false;
	});


	$('.formInfolettre').submit(function() {
		var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		if($("#infolettreemail").val()=="" || !re.test($("#infolettreemail").val())) {
			$("#infolettreemail").fadeIn(300).fadeOut(300).fadeIn(300).fadeOut(300).fadeIn(300);
		} else {
			$.post('/json?module=infolettre&method=add',$(this).serializeArray(),function() {
				$('.formInfolettre').hide().next().removeClass('hidden');
			});

		}
		return false;
	});

	$('body').on('submit','.js-jobForm',function() {
		console.log('AAA');
		var obj = $(this);

		$.post('/json?module=jobForm&method=submit',$(this).serializeArray(),function(e) {
			if(e.status!="Erreur")
				obj.hide().next().removeClass('hide').show();
			else{
				$('.js-error-form').remove();
				$('.js-jobForm').append('<div class="js-error-form alert alert-danger" role="alert">'+e.message+'</div>');
			}
		});
		
		return false;
	});

	$("body").on('click','li.js-noclick a',function(){
		$(this).parent().toggleClass('in');
		return false;
	});

	$(".scrollto").click(function() { // Mettre class .scrollto sur le bouton et mettre attribut data-target="#id" et data-duration="1000"
		var scrolltarget = $(this).data('target');
	    $('html, body').animate({
	        scrollTop:$(scrolltarget).offset().top
	    }, $(this).data('duration'));
	    return false;
	});

	//svgeezy.init();

	$('body').on('touchstart.dropdown', '.dropdown-menu', function (e) { e.stopPropagation(); }); // iPad fix for unclickable nav/subnav

	$('.carousel').carousel({
	  interval: 5000
	});
	
	$("button.close").on('click',function(){
		$(this).parent().slideUp();
	});

	if ($.browser.msie && $.browser.version<9) {
		$('header nav ul > li:last-child').addClass('last').prev().addClass('last');
	}

	if ($('.fancybox').length>0) {
		$('.fancybox').magnificPopup({ 
		  type: 'image',
		  gallery:{enabled:true},
		  callbacks: {
    
		    buildControls: function() {
		      // re-appends controls inside the main container
		      this.contentContainer.append(this.arrowLeft.add(this.arrowRight));
		    }
		    
		  }
		});
	}

	$(".click_tracking").on('click', function(e){
		//e.preventDefault();
		var id = $(this).parent().data('id');
        $.ajax({
            url: '/json?module=cours&method=addclick',
            dateType: 'json',
            async: false,
            data:{id: id},
            success:function(data)
            {
            	return true;
               // window.location = this.href;
            }
        });

	});


	if ($('.fancyboxvideo').length>0) {
		$('.fancyboxvideo').magnificPopup({ 
		  type: 'iframe',
		  gallery:{enabled:true}

		});
	}

	$(window).load(function() {
        $("body").removeClass("preload");
    });

	$('.js-emploiForm .btn').click(function() {
		$('.js-emploiForm').attr('action', '/json?module=emploiform&method=submit');
	});

	$('.js-emploiForm').submit(function() {
		$(this).find('.btn').fadeOut();
	});

	/* Mobile Nav Events START */
	var el = $('.mobile-nav > ul'),
		curHeight = el.height(),
		autoHeight = 0,
		subHeight = 0;
		menuHeight = 40;

	$('.bt-menu, .btn-menu').click(function(){
		    autoHeight = el.css('height', 'auto').height();
		if(!el.hasClass('open')){
			el.height(curHeight).animate({height: autoHeight}, 400);
		}else{
			el.height(autoHeight).animate({height: curHeight}, 400);
		}
		//console.log('click!');
		el.toggleClass('open');
		return false;
	});

	$('.mobile-nav > ul > li > a').click(function(){
		if($(this).parent().children().length > 1){
			$(this).next().addClass('active');
			subHeight = $(this).next().find('ul').height()+menuHeight;
			if(subHeight>autoHeight){
				el.height(autoHeight).animate({height: subHeight}, 200);
			}
			return false;
		}
	});

	$('.mobile-nav > ul > li > div b').click(function(){
		$('.mobile-nav div').removeClass('active');
		if(subHeight>autoHeight){
			el.height(subHeight).animate({height: autoHeight}, 200);
		}
		return false;
	});
	/* Mobile Nav Events END */

	$(window).resize(function() {
		$('.wrapper-facebook .facebook .fb-inner').css('height',$(window).height());
	});
	$('.wrapper-facebook .facebook .fb-inner').css('height',$(window).height());

	$(window).scroll(function(event) {
		var h = parseInt($('header').css('height'));
		if ($(window).scrollTop()>=h) $('.computer .nav-fixed').addClass('active');
		else $('.computer .nav-fixed').removeClass('active');
	});

	$(window).resize(function() {
		$('.home.computer header .wrapper-slider, .home.computer header .wrapper-slider .item').css('height',$(window).height()-40);
	});
	$('.home.computer header .wrapper-slider, .home.computer header .wrapper-slider .item').css('height',$(window).height()-40);

	$(".select-wrapper select").change(function(){
        $(this).parent().find('span').text($(this).parent().find("select option:selected").text());
    });

	$('.custom-accordion').click(function() {
        if ($(this).hasClass('active')) {
            $(this).removeClass('active').next().slideUp();
        }else{
            //$('.custom-accordion.active').removeClass('active').next().slideUp();
            $(this).addClass('active').next().slideDown();
        }
        return false;
    });



    $(window).on('load', function() {
    	// Check all tables. You may need to be more restrictive.
	    $('table.responsive-table').each(function() {
	        var element = $(this);
	        // Create the wrapper element
	        var scrollWrapper = $('<div />', {
	            'class': 'scrollable',
	            'html': '<div />' // The inner div is needed for styling
	        }).insertBefore(element);
	        // Store a reference to the wrapper element
	        element.data('scrollWrapper', scrollWrapper);
	        // Move the scrollable element inside the wrapper element
	        element.appendTo(scrollWrapper.find('div'));
	        // Check if the element is wider than its parent and thus needs to be scrollable
	        if (element.outerWidth() > element.parent().outerWidth()) {
	            element.data('scrollWrapper').addClass('has-scroll');
	        }
	        // When the viewport size is changed, check again if the element needs to be scrollable
	        $(window).on('resize orientationchange', function() {
	            if (element.outerWidth() > element.parent().outerWidth()) {
	                element.data('scrollWrapper').addClass('has-scroll');
	            } else {
	                element.data('scrollWrapper').removeClass('has-scroll');
	            }
	        });
	    });
	});

});


/**
 * Module for displaying "Waiting for..." dialog using Bootstrap
 *
 * @author Eugene Maslovich <ehpc@em42.ru>
 * http://bootsnipp.com/snippets/featured/quotwaiting-forquot-modal-dialog
 * https://github.com/ehpc/bootstrap-waitingfor
 */
var waitingDialog = (function ($) {

    // Creating modal dialog's DOM
	var $dialog = $(
		'<div class="modal fade" data-backdrop="static" data-keyboard="false" tabindex="-1" role="dialog" aria-hidden="true" style="padding-top:15%; overflow-y:visible;">' +
		'<div class="modal-dialog modal-m">' +
		'<div class="modal-content">' +
			'<div class="modal-header"><h3 style="margin:0;"></h3></div>' +
			'<div class="modal-body">' +
				'<div class="progress progress-striped active" style="margin-bottom:0;"><div class="progress-bar" style="width: 100%"></div></div>' +
			'</div>' +
		'</div></div></div>');

	return {
		/**
		 * Opens our dialog
		 * @param message Custom message
		 * @param options Custom options:
		 * 				  options.dialogSize - bootstrap postfix for dialog size, e.g. "sm", "m";
		 * 				  options.progressType - bootstrap postfix for progress bar type, e.g. "success", "warning".
		 */
		show: function (message, options) {
			// Assigning defaults
			var settings = $.extend({
				dialogSize: 'm',
				progressType: ''
			}, options);
			if (typeof message === 'undefined') {
				message = 'Loading';
			}
			if (typeof options === 'undefined') {
				options = {};
			}
			// Configuring dialog
			$dialog.find('.modal-dialog').attr('class', 'modal-dialog').addClass('modal-' + settings.dialogSize);
			$dialog.find('.progress-bar').attr('class', 'progress-bar');
			if (settings.progressType) {
				$dialog.find('.progress-bar').addClass('progress-bar-' + settings.progressType);
			}
			$dialog.find('h3').text(message);
			// Opening dialog
			$dialog.modal();
		},
		/**
		 * Closes dialog
		 */
		hide: function () {
			$dialog.modal('hide');
		}
	};

})(jQuery);